import { render, staticRenderFns } from "./profileDisplay.vue?vue&type=template&id=619de071&scoped=true"
import script from "./profileDisplay.vue?vue&type=script&lang=js"
export * from "./profileDisplay.vue?vue&type=script&lang=js"
import style0 from "./profileDisplay.vue?vue&type=style&index=0&id=619de071&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "619de071",
  null
  
)

export default component.exports